import axios from "axios";
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
export default class Request {
  /**
   * Sends an HTTP request using Axios.
   *
   * @param {string} url - The endpoint URL (relative to the base URL).
   * @param {string} method - The HTTP method (e.g., "GET", "POST").
   * @param {Object} [config={}] - Optional configuration for the Axios request (e.g., headers, data).
   * @param {Object} [utils=undefined] - Optional utilities, such as toast messages for success or error notifications.
   * @returns {Promise} - A promise representing the outcome of the HTTP request.
   */
  static async sendRequest(url, method, config = {}, utils = undefined) {
    const base_url = "https://api.irangardi.app/api/v1/";
    const token = Cookies.get("iran_token");

    const request = axios.request({
      url: base_url + url,
      method: method,
      headers: {
        Authorization: `Bearer ${token}`,
        ...config.headers,
      },
      ...config,
    });

    return Request.handleRequest(request, utils);
  }

  /**
   * Handles the response of the HTTP request, including success and error handling.
   *
   * @param {Promise} request - The promise representing the HTTP request.
   * @param {Object} [utils=undefined] - Optional utilities, such as success toast notifications.
   * @returns {Promise} - A promise with the response data or an error message.
   */
  static async handleRequest(request, utils = undefined) {
    try {
      const response = await request;

      if (utils?.toastSuccess) {
        toast.success(utils.message);
      }
      return response;
    } catch (err) {
      if (err.response.status === 401) {
        return toast.error("نام کاربری یا کلمه عبور اشتباه می باشد");
      }
      toast.error(err.response.data.message);
    }
  }
}
