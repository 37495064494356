import { useEffect } from "react";
import { useLocation } from "react-router-dom";

// *********************** @description *********************** //
// *
// * Custom hook to automatically scroll to the top of the page with a smooth behavior
// * whenever the route changes using React Router.
// *
// ******************************************************** //

const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
};

export default useScrollToTop;
