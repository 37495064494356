import { configureStore } from "@reduxjs/toolkit";
import homeSlice from "../features/home/homeSlice";
import typeSlice from "../features/placeTypes/typeSlice";
import activitySlice from "../features/activity/activitySlice";
import placeSlice from "../features/places/placeSlice";
import authSlice from "../features/auth/authSlice";
import profileSlice from "../features/profile/profileSlice";
import collectionSlice from "../features/collection/collectionSlice";
import blogSlice from "../features/blogs/blogSlice";
import { profileApis } from "../apis/profileApis";
import { backpackApis } from "../apis/backpackApis";
import { mapApis } from "../apis/mapApis";

export const store = configureStore({
  reducer: {
    home: homeSlice,
    types: typeSlice,
    activities: activitySlice,
    places: placeSlice,
    auth: authSlice,
    profile: profileSlice,
    collection: collectionSlice,
    blogs: blogSlice,
    [profileApis.reducerPath]: profileApis.reducer,
    [backpackApis.reducerPath]: backpackApis.reducer,
    [mapApis.reducerPath]: mapApis.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      profileApis.middleware,
      backpackApis.middleware,
      mapApis.middleware
    ),
});
