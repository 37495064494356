import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ****** Services ****** //
import AuthRequest from "../../../services/AuthRequest/AuthRequest";
// ****** Tools ****** //
import { toast } from "react-hot-toast";
import Cookies from "js-cookie";
import axios from "axios";
import { config } from "../../../config/config";
// ****** Sweetalert2 ****** //
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const initialState = {
  loading: false,
  user_checking_loading: false,
  is_user_exist: "idle",
};

const Alert = withReactContent(Swal);

export const checkUserExistence = createAsyncThunk(
  "auth/checkUserExistence",
  async ({ key, value }) => {
    try {
      if (key && value) {
        let data = new FormData();
        data.append(key, value);
        await axios.post(`${config.BASE_URL}/api/v1/user/checkUser`, data);
        return "success";
      }
    } catch (ex) {
      return "error";
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (initialData) => {
    try {
      const { status, data } = await AuthRequest.Login(initialData);
      if (status >= 200) {
        Cookies.set("iran_token", data.access_token, { expires: 30 });
        const redirectUrl = localStorage.getItem("redirectUrl");
        toast.success("ورود به با موفقیت انجام شد");
        if (redirectUrl) {
          localStorage.removeItem("redirectUrl");
          window.location.replace(redirectUrl);
        } else {
          window.location.replace("/");
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const logoutUser = createAsyncThunk("auth/logoutUser", async () => {
  const { sweet_alert_basic } = config;
  try {
    const { isConfirmed } = await Alert.fire({
      title: "برای خروج مطمئن هستید؟",
      ...sweet_alert_basic,
    });

    if (isConfirmed) {
      Cookies.remove("iran_token");
      window.location.replace("/");
      await AuthRequest.Logout();
    }
  } catch (ex) {
    console.log(ex);
  }
});

export const registerOTP = createAsyncThunk(
  "auth/registerOTP",
  async (initialData) => {
    try {
      const { status } = await AuthRequest.Regsiter(initialData);
      if (status >= 200) {
        setTimeout(() => {
          window.location.replace("/active-user");
        }, 1500);
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const registerInfo = createAsyncThunk(
  "auth/registerInfo",
  async (initialData) => {
    try {
      const { status, data } = await AuthRequest.RegsiterInfo(initialData);
      if (status >= 200) {
        Cookies.set("iran_token", data["0"]["original"]);
        window.location.replace("/register-activites");
        localStorage.removeItem("mobile");
        localStorage.removeItem("email");
        toast.success("اطلاعات با موفقیت ذخیره شد");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const activeUserBySms = createAsyncThunk(
  "auth/activeUser",
  async ({ initialData, is_forget_password }) => {
    try {
      const { status } = await AuthRequest.Activation(initialData);
      if (status >= 200) {
        toast.success("موفقیت آمیز بود");
        if (!is_forget_password) {
          window.location.replace("/register-info");
        } else {
          window.location.replace("/change-password");
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (initialData) => {
    try {
      const { status } = await AuthRequest.ForgetPassword(initialData);
      if (status >= 200) {
        window.location.replace("/reset-password");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const changePassword = createAsyncThunk(
  "auth/changePassword",
  async (initialData) => {
    try {
      const { status } = await AuthRequest.ChangePassword(initialData);
      if (status >= 200) {
        window.location.replace("/login");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(registerOTP.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerOTP.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(activeUserBySms.pending, (state) => {
        state.loading = true;
      })
      .addCase(activeUserBySms.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(registerInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(registerInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(checkUserExistence.pending, (state) => {
        state.user_checking_loading = true;
        state.is_user_exist = "idle";
      })
      .addCase(checkUserExistence.fulfilled, (state, { payload }) => {
        state.is_user_exist = payload;

        state.user_checking_loading = false;
      });
  },
});

export default authSlice.reducer;
