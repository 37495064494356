import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../config/config";
import Cookies from "js-cookie";

/**
 * backpackApis is an API slice created using Redux Toolkit's createApi.
 * It handles requests related to the backpack feature, such as fetching, adding, renaming,
 * deleting, and submitting backpack items and categories.
 */
export const backpackApis = createApi({
  reducerPath: "backPack",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers) => {
      const token = Cookies.get("iran_token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Back_Pack_Category"],
  endpoints: (builder) => ({
    /**
     * Fetches all backpack categories.
     *
     * @returns {Object} The response containing the backpack categories.
     */
    getAllBackPackCategories: builder.query({
      query: () => "/api/v1/profile/todoList",
      providesTags: ["Back_Pack_Category"],
    }),
    /**
     * Adds a new backpack category.
     *
     * @param {Object} data - The data to create a new backpack category.
     * @returns {Object} The response of the request.
     */
    addNewBackPackCategory: builder.mutation({
      query: (data) => ({
        url: "/api/v1/profile/todoList/todoCustom/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    /**
     * Removes a specific backpack category by its ID.
     *
     * @param {number|string} todoCustom_id - The ID of the backpack category to be removed.
     * @returns {Object} The response of the request.
     */
    removeBackPackCategory: builder.mutation({
      query: (todoCustom_id) => ({
        url: `/api/v1/profile/todoList/todoCustom/destroy/${todoCustom_id}`,
        method: "POST",
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    /**
     * Adds a new backpack item.
     *
     * @param {Object} data - The data to create a new backpack item.
     * @returns {Object} The response of the request.
     */
    addNewBackPackItem: builder.mutation({
      query: (data) => ({
        url: "/api/v1/profile/todoList/todoCustom/store",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    /**
     * Renames a specific backpack item by its ID.
     *
     * @param {Object} params - Contains the todo_id and the new name for the item.
     * @returns {Object} The response of the request.
     */
    renameBackPackItem: builder.mutation({
      query: ({ todo_id, newName }) => ({
        url: `/api/v1/profile/todoList/todoCustom/rename/${todo_id}?name=${newName}`,
        method: "POST",
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    /**
     * Deletes a specific backpack item by its ID.
     *
     * @param {number|string} todo_id - The ID of the backpack item to be deleted.
     * @returns {Object} The response of the request.
     */
    deleteBackPackItem: builder.mutation({
      query: (todo_id) => ({
        url: `/api/v1/profile/todoList/todoCustom/destroy/${todo_id}`,
        method: "POST",
      }),
      invalidatesTags: ["Back_Pack_Category"],
    }),
    /**
     * Submits the state of checkboxes (to-do items).
     *
     * @param {Object} body - The body of the request containing checkbox data.
     * @returns {Object} The response of the request.
     */
    submitCheckboxes: builder.mutation({
      query: (body) => ({
        url: "/api/v1/profile/todoList/todo/check",
        body,
        method: "POST",
      }),
    }),
  }),
});

export const {
  useAddNewBackPackCategoryMutation,
  useGetAllBackPackCategoriesQuery,
  useDeleteBackPackItemMutation,
  useAddNewBackPackItemMutation,
  useRenameBackPackItemMutation,
  useSubmitCheckboxesMutation,
  useRemoveBackPackCategoryMutation,
} = backpackApis;
