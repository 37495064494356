import React from "react";
import Cookies from "js-cookie";
// ****** React Router DOM ****** //
import { Outlet, Navigate } from "react-router-dom";

/**
 * PrivateRoutes component checks for an authentication token in cookies.
 *
 * - If the "iran_token" cookie is present, it renders the child routes (`<Outlet />`).
 * - If the "iran_token" cookie is missing, it redirects the user to the login page (`/login`).
 *
 * This component is used to protect routes that require authentication.
 *
 * @returns {JSX.Element} Either the `Outlet` component for rendering child routes or `Navigate` to redirect to the login page.
 */
const PrivateRoutes = () => {
  return Cookies.get("iran_token") ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
