import { Suspense, lazy, useEffect } from "react";
// ****** React Router DOM ****** //
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Loading from "./utils/Loading";
import PrivateRoutes from "./utils/PrivateRoutes";
import useScrollToTop from "./hooks/useScrollToTop.js";
import ErrorBoundary from "./utils/ErrorBoundary.jsx";
// !====== Custom components ======! //
const MainLayouts = lazy(() => import("./layouts/MainLayouts"));
const PlaceListPage = lazy(() => import("./pages/PlaceListPage"));
const AttractionsCategoryPage = lazy(() =>
  import("./pages/Category/AttractionsCategoryPage")
);
const AttractionCategoryDetails = lazy(() =>
  import("./components/Attractions/AttractionCategoryDetails.jsx")
);
const BlogDetails = lazy(() => import("./pages/Blog/BlogDetails.jsx"));
// !====== Login & Register ======! //
const Login = lazy(() => import("./pages/Forms/Login/Login"));
const Register = lazy(() => import("./pages/Forms/Register/Register"));
const UpdatePassword = lazy(() =>
  import("./pages/Forms/ForgetPassword/UpdatePassword")
);
const SelectActivities = lazy(() =>
  import("./pages/Forms/Register/SelectActivities")
);
const RegisterOTP = lazy(() => import("./pages/Forms/Register/RegsiterOTP"));
const VerifyCode = lazy(() =>
  import("./pages/Forms/ForgetPassword/VerifyCode")
);
const ChangePassword = lazy(() =>
  import("./pages/Forms/ForgetPassword/ChangePassword")
);
// !====== Activite components ======! //
const ActivitiesCategoryPage = lazy(() =>
  import("./pages/Category/ActivitiesCategoryPage")
);
const ActivitiesSubsetPage = lazy(() =>
  import("./pages/Subset/ActivitiesSubsetPage")
);
const ActivitieDetailsPage = lazy(() =>
  import("./pages/ActivitieDetails/ActivitieDetailsPage")
);
const ActivitiesSubsetDetails = lazy(() =>
  import("./components/ActivitiesSubsetDetails/ActivitiesSubsetDetails.jsx")
);
// !====== Core Pages ======! //
const Home = lazy(() => import("./pages/Home/HomePage"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUsPage"));
const FAQPage = lazy(() => import("./pages/FAQs/FAQPage"));
const MapPage = lazy(() => import("./pages/Map/MapPage"));
const PlaceDetails = lazy(() =>
  import("./pages/PlaceDetails/PlaceDetailsPage")
);
const TermsPage = lazy(() => import("./pages/Terms&Conditions/TermsPage"));
const ContactUsPage = lazy(() => import("./pages/ContactUs/ContactUsPage"));
const ProvincesPage = lazy(() => import("./pages/ProvincesMap/ProvincesPage"));
const BlogsPage = lazy(() => import("./pages/Blog/BlogsPage"));
const DonationPage = lazy(() => import("./pages/Donation/DonationPage.jsx"));
// !====== Profile Components ======! //
const Profile = lazy(() => import("./pages/Profile/ProfilePage"));
const ProfileAccountInfo = lazy(() =>
  import("./components/Profile/AccountInformation/ProfileAccountInfo")
);
const ProfileCollectionList = lazy(() =>
  import("./components/Profile/FavouriteList/ProfileCollectionList.jsx")
);
const Notifications = lazy(() =>
  import("./components/Profile/Notificatinos/Notifications")
);
const BackPack = lazy(() => import("./components/Profile/BackPack/BackPack"));
const MyComments = lazy(() =>
  import("./components/Profile/MyComments/MyComments")
);

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useScrollToTop();

  useEffect(() => {
    window.lazySizes.init();
  }, []);

  useEffect(() => {
    return () => {
      if (pathname !== "/register-info") {
        localStorage.removeItem("mobile");
        localStorage.removeItem("email");
      }
    };
  }, [pathname, navigate]);

  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<RegisterOTP />} />
          <Route path="/active-user" element={<VerifyCode />} />
          <Route path="/register-info" element={<Register />} />
          <Route path="/forget" element={<ChangePassword />} />
          <Route path="/reset-password" element={<VerifyCode />} />
          <Route path="/register-activites" element={<SelectActivities />} />
          <Route path="/change-password" element={<UpdatePassword />} />

          <Route path="/" element={<MainLayouts />}>
            <Route index element={<Home />} />
            <Route path="/place-list" element={<PlaceListPage />} />
            <Route path="/place-details/:placeId" element={<PlaceDetails />} />
            <Route
              path="/attractions-category"
              element={<AttractionsCategoryPage />}
            />
            <Route
              path="/attractions-category-details/:id"
              element={<AttractionCategoryDetails />}
            />
            <Route
              path="/activities-category"
              element={<ActivitiesCategoryPage />}
            />
            <Route
              path="/activities-subset/:id"
              element={<ActivitiesSubsetPage />}
            />
            <Route
              path="/activities-subset-details/:id"
              element={<ActivitiesSubsetDetails />}
            />
            <Route
              path="/activite-details/:id"
              element={<ActivitieDetailsPage />}
            />
            <Route path="/donation" element={<DonationPage />} />
            <Route path="/map" element={<MapPage />} />
            <Route path="/provinces" element={<ProvincesPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/terms" element={<TermsPage />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/blog/:id" element={<BlogDetails />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route element={<PrivateRoutes />}>
              <Route path="/profile" element={<Profile />}>
                <Route index element={<ProfileAccountInfo />} />
                <Route path="notification" element={<Notifications />} />
                <Route path="backpack" element={<BackPack />} />
                <Route path="comments" element={<MyComments />} />
                <Route path="favourite" element={<ProfileCollectionList />} />
              </Route>
            </Route>
          </Route>
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
