import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserRequest from "../../../services/UserRequrest/UserRequest";
import { toast } from "react-toastify";
import { ProfileRequest } from "../../../services/ProfileRequest/ProfileRequest";

const initialState = {
  loading: false,
  rating_loading: false,
  update_loading: false,
  password_loading: false,
  profile: {},
  notifications: {},
  my_comments: [],
};

export const getUserProfile = createAsyncThunk(
  "profile/getUserProfile",
  async () => {
    try {
      const { data } = await UserRequest.GetProfile();
      return data.user;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateUserProfile = createAsyncThunk(
  "profile/updateUserProfile",
  async (initialData) => {
    try {
      const { status } = await UserRequest.UpdateProfile(initialData);
      if (status >= 200) {
        window.location.reload();
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateUserAvatar = createAsyncThunk(
  "profile/updateUserAvatar",
  async (initialData) => {
    try {
      await UserRequest.PorfileAvatar(initialData);
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  "profile/updateUserPassword",
  async (initialData) => {
    try {
      const { status } = await UserRequest.ProfileChagnePassword(initialData);
      if (status >= 200) {
        toast.success("رمز عبور با موفقیت تغییر کرد");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const userTodoListItemChecks = createAsyncThunk(
  "profile/userTodoListItemChecks",
  async (todo_id) => {
    try {
      await UserRequest.CheckedTodoListItems(todo_id);
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const rateThePlace = createAsyncThunk(
  "profile/rateThePlace",
  async ({ place_id, rate }) => {
    try {
      const { status } = await UserRequest.PlaceRating(place_id, rate);
      if (status >= 200) {
        toast.success("امتیاز شما با موفقیت ثبت شد");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllNotifications = createAsyncThunk(
  "notifications/getAllNotifications",
  async () => {
    try {
      const { data } = await UserRequest.Notifications();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const setSeenNotification = createAsyncThunk(
  "notifications/setSeenNotification",
  async (notif_id) => {
    try {
      await UserRequest.SeenNotification(notif_id);
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllMyComments = createAsyncThunk(
  "profile/getAllMyComments",
  async () => {
    try {
      const { data } = await ProfileRequest.MyCommentsList();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.loading = false;
      })
      .addCase(updateUserProfile.pending, (state) => {
        state.update_loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state) => {
        state.update_loading = false;
      })
      .addCase(rateThePlace.pending, (state) => {
        state.rating_loading = true;
      })
      .addCase(rateThePlace.fulfilled, (state) => {
        state.rating_loading = false;
      })
      .addCase(getAllNotifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload;
        state.loading = false;
      })
      .addCase(getAllMyComments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMyComments.fulfilled, (state, { payload }) => {
        state.my_comments = payload;
        state.loading = false;
      })
      .addCase(updateUserPassword.pending, (state) => {
        state.password_loading = true;
      })
      .addCase(updateUserPassword.fulfilled, (state) => {
        state.password_loading = false;
      });
  },
});

export default profileSlice.reducer;
