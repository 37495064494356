import Request from "../Request";

export class ProfileRequest extends Request {
  /**
   * Fetches the list of the user's comments from the server.
   *
   * @returns {Promise} - A promise representing the outcome of the request.
   */
  static MyCommentsList() {
    return this.sendRequest("profile/myComment", "GET", {}, {});
  }
}
