export const config = {
  BASE_URL: "https://api.irangardi.app",
  react_select_custom_style:{
    control: (baseStyles, state) => ({
      ...baseStyles,
      background: "#ededed",
      fontSize: "12px",
      borderColor: state.isFocused ? "#35c075" : "#ededed",
      ":hover": {
        borderColor: "#35c075",
      },
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: "12px",
      ":focus":{
        background:"red"
      }
    }),
  },
  mapbox_token:
    "pk.eyJ1IjoibWVoZGk2NjYiLCJhIjoiY2lzM2piNml0MDAwczJvcDdqdjN5aGoyaSJ9.LsyabTkEFUEfkuUGNeU-RQ",
  mapbox_style: "mapbox://styles/mapbox/outdoors-v11",
  mapbox_rtl_plugin_url:
    "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
  sweet_alert_basic: {
    showCancelButton: true,
    showConfirmButton: true,
    cancelButtonText: "خیر",
    confirmButtonText: "بله",
    cancelButtonColor: "red",
    confirmButtonColor: "green",
  },
};
