import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../config/config";

/**
 * mapApis is an API slice created using Redux Toolkit's createApi.
 * It handles requests related to map functionalities, such as fetching the nearest places
 * based on geographic coordinates.
 */
export const mapApis = createApi({
  reducerPath: "map",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
  }),
  endpoints: (builder) => ({
    /**
     * Fetches the nearest places based on latitude, longitude, and zoom level.
     * Sends a POST request with additional body data to filter the results.
     *
     * @param {Object} params - The parameters for fetching the nearest places.
     * @param {number} params.lat - The latitude of the location.
     * @param {number} params.lon - The longitude of the location.
     * @param {number} params.zoom - The zoom level for the map.
     * @param {Object} params.body - The additional request body data for filtering.
     * @returns {Object} The response from the API.
     */
    getNearestPlaces: builder.mutation({
      query: ({ lat, lon, zoom, body }) => ({
        url: `api/v1/filter/nearby/${lat}/${lon}/${zoom}`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetNearestPlacesMutation } = mapApis;
