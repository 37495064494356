import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
// ****** Request Services ****** //
import { BlogRequest } from "../../../services/BlogRequest/BlogRequest";
import UserRequest from "../../../services/UserRequrest/UserRequest";
// ****** SweetAlert2 ****** //
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const initialState = {
  loading: false,
  blogs: [],
  blog_categories: [],
  blog_collections: [],
  blog: {},
};

const Alert = withReactContent(Swal);

export const getAllBlogCategories = createAsyncThunk(
  "blogs/getAllBlogCategories",
  async () => {
    try {
      const { data } = await BlogRequest.GetBlogCategories();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllBlogs = createAsyncThunk(
  "home/getAllBlogs",
  async ({ page, category_id }) => {
    try {
      const { data } = await BlogRequest.GetBlogsList(page, null, category_id);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getBlogById = createAsyncThunk(
  "home/getBlogById",
  async (post_id) => {
    try {
      const { data } = await BlogRequest.GetBlogsList(1, post_id);
      return data.data[0];
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewBlogComment = createAsyncThunk(
  "home/addNewBlogComment",
  async ({ blog_id, data }) => {
    try {
      const { status } = await UserRequest.AddBlogComment(blog_id, data);
      if (status >= 200) {
        Alert.fire({
          title: "کامنت با موفقیت  ثبت شد",
          text: "کامنت شما بعد از بررسی در وب سایت قرار خواهد گرفت",
          confirmButtonText: "باشه",
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllBlogsCollection = createAsyncThunk(
  "blogs/getAllBlogsCollection",
  async () => {
    try {
      const { data } = await UserRequest.GetPostCollections();
      return data?.original;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addBlogToCollections = createAsyncThunk(
  "blogs/addBlogToCollections",
  async ({ type, post_id }) => {
    try {
      const { status } = await UserRequest.AddPostCollection(type, post_id);
      if (status >= 200) {
        toast.success("مقاله با موفقیت به لیست شما اضافه شد");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllBlogCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogCategories.fulfilled, (state, { payload }) => {
        state.blog_categories = payload;
        state.loading = false;
      })
      .addCase(getAllBlogs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, { payload }) => {
        state.blogs = payload;
        state.loading = false;
      })
      .addCase(getBlogById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBlogById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.blog = payload;
      })
      .addCase(getAllBlogsCollection.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBlogsCollection.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.blog_collections = payload;
      });
  },
});

export default blogSlice.reducer;
