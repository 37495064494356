import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserRequest from "../../../services/UserRequrest/UserRequest";
import { toast } from "react-hot-toast";
// ****** Sweetalert2 ****** //
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { config } from "../../../config/config";

const initialState = {
  loading: false,
  collections: {},
};

const Alert = withReactContent(Swal);

export const getAllCollections = createAsyncThunk(
  "collection/getAllCollections",
  async () => {
    try {
      const { data } = await UserRequest.GetCollection();
      return data.original;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewCustomCollection = createAsyncThunk(
  "collection/addNewCustomCollection",
  async (initialData) => {
    try {
      const { status } = await UserRequest.CreateCustomCollection(initialData);
      if (status >= 200) {
        toast.success("لیست شما اضافه شد");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewCollection = createAsyncThunk(
  "collection/addNewCollection",
  async ({ type, place_id }) => {
    try {
      const { status } = await UserRequest.AddCollection(type, place_id);
      if (status >= 200) {
        if (type.includes("bookmark")) {
          toast.success("با موفقیت به لیست نشان شده شما اضافه شد");
        } else {
          toast.success("با موفقیت به لیست علاقمندی شما اضافه شد");
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeCollection = createAsyncThunk(
  "collection/removeCollection",
  async (collection_id) => {
    try {
      const { status } = await UserRequest.RemoveCollection(collection_id);
      if (status >= 200) {
        window.location.reload();
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeCustomCollection = createAsyncThunk(
  "collection/removeCustomCollection",
  async (collection_id) => {
    const { sweet_alert_basic } = config;
    try {
      const { isConfirmed } = await Alert.fire({
        title: "حذف شود؟",
        ...sweet_alert_basic,
      });
      if (isConfirmed) {
        const { status } = await UserRequest.RemoveCustomCollection(
          collection_id
        );
        if (status >= 200) {
          window.location.reload();
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const collectionSlice = createSlice({
  name: "collection",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllCollections.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCollections.fulfilled, (state, { payload }) => {
        state.collections = payload;
        state.loading = false;
      })
      .addCase(addNewCollection.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewCollection.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removeCollection.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeCollection.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addNewCustomCollection.pending, (state) => {
        state.loading = true;
      })
      .addCase(addNewCustomCollection.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default collectionSlice.reducer;
