import Request from "../Request";

export class BlogRequest extends Request {
  /**
   * Fetches the list of blog categories from the server.
   *
   * @returns {Promise} - A promise representing the outcome of the request.
   */

  static GetBlogCategories() {
    return this.sendRequest("home/category", "GET", {}, {});
  }

  /**
   * Fetches the list of blogs from the server, with optional pagination, post ID, and category ID.
   *
   * @param {number} [page=1] - The page number for pagination (default is 1).
   * @param {number} [post_id] - The optional ID of a specific post to filter the results.
   * @param {number} [category_id] - The optional ID of a category to filter the results.
   * @returns {Promise} - A promise representing the outcome of the request.
   */
  static GetBlogsList(page = 1, post_id, category_id) {
    return this.sendRequest(
      `home/post?page=${page}${post_id ? `&post_id=${post_id}` : ""}${
        category_id ? `&category_id=${category_id}` : ""
      }`,
      "GET",
      {},
      {}
    );
  }
}
