import React from "react";
import Lottie from "lottie-react";
import Loader from "../assets/images/loader/data.json";

const Loading = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center "
      style={{ height: "100vh" }}
    >
      <Lottie animationData={Loader} className="lottie-loading"/>
    </div>
  );
};

export default Loading;
