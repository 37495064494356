import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error occurred within the ErrorBoundary", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-handler text-muted d-flex justify-content-center flex-column text-center ">
          <div className="bg-white py-3 mx-auto">
            <h4 className="pb-4">متاسفانه خطای غیر منتظره ای رخ داده است</h4>
            <p>لطفا دوباره تلاش کنید</p>
            <a href="/" className="btn  btn-success">
              صفحه اصلی
            </a>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
