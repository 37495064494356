import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HomeRequest from "../../../services/HomeRequest/HomeRequest";

const initialState = {
  loading: false,
  types: [],
};

export const getAllTypes = createAsyncThunk("types/getAllTypes", async () => {
  try {
    const { data } = await HomeRequest.PlacesTypes();
    return data.types;
  } catch (ex) {
    console.log(ex);
  }
});

export const typeSlice = createSlice({
  name: "types",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllTypes.fulfilled, (state, { payload }) => {
        state.types = payload;
        state.loading = false;
      });
  },
});

export default typeSlice.reducer;
