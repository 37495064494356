import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HomeRequest from "../../../services/HomeRequest/HomeRequest";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import toast from "react-hot-toast";

const initialState = {
  loading: false,
  newsletter_loading: false,
  search_loading: false,
  places: [],
  lates: [],
  mostView: [],
  lastView: [],
  best: [],
  provinces: [],
  cities: [],
  faqs: [],
  search_result: [],
  sliders: [],
  posts: [],
  last_seen: [],
  search_history: [],
  search_history_deleted: false,
};

const Alert = withReactContent(Swal);

export const getAllLatestPlaces = createAsyncThunk(
  "home/getAllLatestPlaces",
  async () => {
    try {
      const { data } = await HomeRequest.LatestPlaces();
      return data.latestPlace;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllBestPlaces = createAsyncThunk(
  "home/getAllBestPlaces",
  async () => {
    try {
      const { data } = await HomeRequest.BestPlaces();
      return data.bestPlaces;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllMostViewPlaces = createAsyncThunk(
  "home/getAllMostViewPlaces",
  async () => {
    try {
      const { data } = await HomeRequest.MostView();
      return data.mostViewPlaces;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllLastViewPlaces = createAsyncThunk(
  "home/getAllLastViewPlaces",
  async () => {
    try {
      const { data } = await HomeRequest.LatestPlaces();
      return data.latestPlace;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllProvinces = createAsyncThunk(
  "home/getAllProvinces",
  async () => {
    try {
      const { data } = await HomeRequest.GetProvices();
      return data.provinces;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllCities = createAsyncThunk("home/getAllCities", async () => {
  try {
    const { data } = await HomeRequest.GetCities();
    return data.city;
  } catch (ex) {
    console.log(ex);
  }
});

export const getAllFAQs = createAsyncThunk("home/getALlFAQs", async () => {
  try {
    const { data } = await HomeRequest.FAQList();
    return data.faqs;
  } catch (ex) {
    console.log(ex);
  }
});

export const searchOnPlaces = createAsyncThunk(
  "home/searchOnPlaces",
  async (search) => {
    try {
      if (search === "") return;
      const { data } = await HomeRequest.SearchPlaces(search);
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllUserSearchHistory = createAsyncThunk(
  "home/getAllUserSearchHistory",
  async () => {
    try {
      const { data } = await HomeRequest.UserSearchHistory();
      return data.history;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const removeUserSearchHistory = createAsyncThunk(
  "home/removeUserSearchHistory",
  async (id) => {
    try {
      const { status } = await HomeRequest.DeleteUserSearchHistory(id);
      return status;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllSliders = createAsyncThunk(
  "home/getAllSliders",
  async () => {
    try {
      const { data } = await HomeRequest.HomeSliders();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const sendContactUsMassage = createAsyncThunk(
  "home/sendContactUsMassage",
  async (initialData) => {
    try {
      const { status } = await HomeRequest.ContactUs(initialData);
      if (status >= 200) {
        Alert.fire({
          title: "درخواست شما ارسال شد",
          text: "نظر شما با موفقیت ارسال شد و پس از بررسی به شما تماس خواهیم گرفت",
          confirmButtonText: "باشه",
          icon: "success",
        });

        Cookies.remove("reported_place_name");
        setTimeout(() => {
          window.location.replace("/");
        }, 3000);
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getLatestPosts = createAsyncThunk(
  "home/getLatestPosts",
  async () => {
    try {
      const { data } = await HomeRequest.LatestPosts();
      return data.data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getLatestSeenPlaces = createAsyncThunk(
  "home/getLatestSeenPlaces",
  async () => {
    try {
      const { data } = await HomeRequest.LastSeen();
      return data.data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const submitNewsletters = createAsyncThunk(
  "home/submitNewsletters",
  async (initialData) => {
    try {
      const { status } = await HomeRequest.SubmitNewsletters(initialData);
      if (status) {
        toast.success("ایمیل با موفقیت ثبت شد");
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const homeSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllLatestPlaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllLatestPlaces.fulfilled, (state, { payload }) => {
        state.lates = payload;
        state.loading = false;
      })
      .addCase(getAllBestPlaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBestPlaces.fulfilled, (state, { payload }) => {
        state.best = payload;
        state.loading = false;
      })
      .addCase(getAllMostViewPlaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllMostViewPlaces.fulfilled, (state, { payload }) => {
        state.mostView = payload;
        state.loading = false;
      })
      .addCase(getAllProvinces.fulfilled, (state, { payload }) => {
        state.provinces = payload;
      })
      .addCase(getAllCities.fulfilled, (state, { payload }) => {
        state.cities = payload;
      })
      .addCase(getAllFAQs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllFAQs.fulfilled, (state, { payload }) => {
        state.faqs = payload;
        state.loading = false;
      })
      .addCase(searchOnPlaces.pending, (state) => {
        state.search_loading = true;
      })
      .addCase(searchOnPlaces.fulfilled, (state, { payload }) => {
        state.search_result = payload;
        state.search_loading = false;
      })
      .addCase(getAllSliders.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSliders.fulfilled, (state, { payload }) => {
        state.sliders = payload;
        state.loading = false;
      })
      .addCase(sendContactUsMassage.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendContactUsMassage.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getLatestPosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLatestPosts.fulfilled, (state, { payload }) => {
        state.posts = payload;
        state.loading = false;
      })
      .addCase(getLatestSeenPlaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLatestSeenPlaces.fulfilled, (state, { payload }) => {
        state.last_seen = payload;
      })
      .addCase(submitNewsletters.pending, (state) => {
        state.newsletter_loading = true;
      })
      .addCase(submitNewsletters.fulfilled, (state) => {
        state.newsletter_loading = false;
      })
      .addCase(getAllUserSearchHistory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUserSearchHistory.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.search_history = payload;
      })
      .addCase(removeUserSearchHistory.fulfilled, (state) => {
        state.search_history_deleted = true;
        setTimeout(() => {
          state.search_history_deleted = false;
        }, 1500);
      });
  },
});

export default homeSlice.reducer;
