import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { config } from "../../config/config";
import Cookies from "js-cookie";

/**
 * profileApis is an API slice created using Redux Toolkit's createApi.
 * It handles requests related to user profile activities, such as fetching user activities,
 * adding activities to favorites, and removing activities from favorites.
 */
export const profileApis = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.BASE_URL,
    prepareHeaders: (headers) => {
      const token = Cookies.get("iran_token");

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Activites"],
  endpoints: (builder) => ({
    /**
     * Fetches the current user's activities.
     *
     * @returns {Object} The response containing the user's activities.
     */
    getUserActivites: builder.query({
      query: () => "/api/v1/profile/activity",
      providesTags: ["Activites"],
    }),
    /**
     * Fetches all available activities.
     *
     * @returns {Object} The response containing all available activities.
     */
    getAllActivites: builder.query({
      query: () => "/api/v1/profile/get/activity",
      providesTags: ["Activites"],
    }),
    /**
     * Adds a specific activity to the user's favorites.
     *
     * @param {number|string} activity_id - The ID of the activity to add to favorites.
     * @returns {Object} The response of the request.
     */
    addActivityToUserFavourite: builder.mutation({
      query: (activity_id) => ({
        url: `/api/v1/profile/store/activity/${activity_id}`,
        method: "POST",
      }),
      invalidatesTags: ["Activites"],
    }),
    /**
     * Removes a specific activity from the user's favorites.
     *
     * @param {number|string} activity_id - The ID of the activity to remove from favorites.
     * @returns {Object} The response of the request.
     */
    deleteActivityFromUserFavourite: builder.mutation({
      query: (activity_id) => ({
        url: `/api/v1/profile/destroy/activity/${activity_id}`,
        method: "POST",
      }),
      invalidatesTags: ["Activites"],
    }),
  }),
});

export const {
  useGetUserActivitesQuery,
  useGetAllActivitesQuery,
  useAddActivityToUserFavouriteMutation,
  useDeleteActivityFromUserFavouriteMutation,
} = profileApis;
