import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "lazysizes";
import "bootstrap/dist/js/bootstrap.bundle";
// ****** Styles ****** //
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/sass/style.scss";
import "react-rater/lib/react-rater.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "mapbox-gl/dist/mapbox-gl.css";
import "react-toastify/dist/ReactToastify.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "tippy.js/dist/tippy.css";
import "react-range-slider-input/dist/style.css";
import "react-lazy-load-image-component/src/effects/blur.css";

// ****** Redux Config ****** //
import { Provider } from "react-redux";
import { store } from "./app/store";
// ****** Tools ****** //
import { Toaster } from "react-hot-toast";
import { ToastContainer } from "react-toastify";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </Provider>
    <ToastContainer theme="colored" />
    <Toaster position="top-left" />
  </BrowserRouter>
);
