import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import HomeRequest from "../../../services/HomeRequest/HomeRequest";

const initialState = {
  loading: false,
  activities: [],
  sub_activities: [],
};

export const getAllActivities = createAsyncThunk(
  "activities/getAllActivities",
  async () => {
    try {
      const { data } = await HomeRequest.Activities();
      return data.activities;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getActivityById = createAsyncThunk(
  "activities/getActivityById",
  async (activity_id) => {
    try {
      const { data } = await HomeRequest.GetActivity(activity_id);
      return data.activities;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getAllSubActivites = createAsyncThunk(
  "activities/getAllSubActivites",
  async () => {
    try {
      const { data } = await HomeRequest.GetSubActivites();
      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const activitySlice = createSlice({
  name: "activities",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllActivities.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllActivities.fulfilled, (state, { payload }) => {
        state.activities = payload;
        state.loading = false;
      })
      .addCase(getActivityById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivityById.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sub_activities = payload;
      })
      .addCase(getAllSubActivites.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSubActivites.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sub_activities = payload;
      });
  },
});

export default activitySlice.reducer;
