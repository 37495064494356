import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// ****** Request Services ****** //
import HomeRequest from "../../../services/HomeRequest/HomeRequest";
import UserRequest from "../../../services/UserRequrest/UserRequest";
// ****** SweetAlert2 ****** //
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const Alert = withReactContent(Swal);

const initialState = {
  loading: false,
  places: [],
  place: {},
  place_weather: {},
  isCollection: {},
  similar_place: [],
};

export const getAllPlaces = createAsyncThunk(
  "places/getAllPlaces",
  async () => {
    try {
      const { data } = await HomeRequest.PlacesList();
      return data.places;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const getPlaceById = createAsyncThunk(
  "places/getPlaceById",
  async (place_id) => {
    try {
      const { data } = await HomeRequest.GetPlace(place_id);

      return data;
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const addNewPlaceComment = createAsyncThunk(
  "places/addNewPlaceComment",
  async ({ place_id, comment }) => {
    try {
      const { status } = await UserRequest.AddPlaceComment(place_id, comment);
      if (status >= 200) {
        Alert.fire({
          title: "کامنت با موفقیت  ثبت شد",
          text: "کامنت شما بعد از بررسی در وب سایت قرار خواهد گرفت",
          confirmButtonText: "باشه",
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }
);

export const placeSlice = createSlice({
  name: "places",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllPlaces.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlaces.fulfilled, (state, { payload }) => {
        state.places = payload;
        state.loading = false;
      })
      .addCase(getPlaceById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlaceById.fulfilled, (state, { payload }) => {
        state.isCollection = payload.isCollection;
        state.place = payload.place;
        state.place_weather = payload.weather;
        state.similar_place = payload.similar_place;
        state.loading = false;
      });
  },
});

export default placeSlice.reducer;
